import Resume from "../../components/resume/Resume";
import {PersonType} from "../../model/PersonType";
import {PageProps} from "gatsby";
import React from "react";
import {siteMetaData} from "../../siteMetaData";

export default (props: PageProps) => {
  const language = "en"
  return (
    <Resume
      personType={PersonType.FirstPersonSingular}
      language={language}
      href={siteMetaData.getUrlForPath(props.location.pathname)}
    />
  )
}
